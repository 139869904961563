// The actual type is defined in vee-validate. I dind't know how to import that here
// so I created an interface that matches it.

// Vee validate's type:
// InvalidSubmissionContext<TValues> is the vee-validate type
// interface InvalidSubmissionContext<
//   TValues extends GenericObject = GenericObject,
// > {
//   values: TValues
//   evt?: Event
//   errors: Partial<Record<Path<TValues>, string>>
//   results: Partial<Record<Path<TValues>, ValidationResult>>
// }

interface HandlerErrorContext {
  errors: Partial<Record<any, string>>
}

export default function useFormValidationErrorHandler() {
  const { toast } = useErrorToaster()
  const { t: $t } = useI18n()

  function handleValidationError(ctx: HandlerErrorContext) {
    for (const [_, value] of Object.entries(ctx.errors)) {
      console.error(value)
      toast({
        title: $t('errors.validationError'),
        message: value,
      })
      // Just show the first error for now, otherwise we'll get a long stream of toasts
      return
    }
  }

  return { handleValidationError }
}
